import React from 'react';

const PaymentMethod = ({ id }) => {
  const displayArray = [
    { label: 'QR Code Scan', value: 'Scan the below QR Code for digital payment' },    
    { label: 'UPI Id', value: 'xxxxyyyzz@oksbi' }
  ]
  return (
    <>
      <div className="tab-pane fade" id={id} role="tabpanel" tabIndex="0">
        {
          displayArray && displayArray.length > 0 && displayArray.map((obj) => {
            return (
              <div className="col-12 mb-4">
                <h6>{obj.label}</h6>
                <p>{obj.value}</p>
              </div>
            )
          })
        }
      </div>
    </>
  )
};

export default PaymentMethod;
