import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-responsive-modal';
import { changeInputFunc, resetMyAccountReducerFunc } from './reducers/myAccountSlice';
import { ToastContainer, toast } from 'react-toastify';
import { toastOptions } from './toastify';
import DisplayProfile from './components/DisplayProfile';
import EditProfile from './components/EditProfile';
import ChangePassword from './components/ChangePassword';
import Subscription from './components/Subscription';
import PaymentMethod from './components/PaymentMethod';
import { handleAPIData } from './hooks/useCustomApi';
import store from './store';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-responsive-modal/styles.css';

const App = ({ message }) => {
  const dispatch = useDispatch();
  const [myAccountSection, setMyAccountSection] = useState(false);
  const [open, setOpen] = useState(false);
  const [userDetails, setUserDetails] = React.useState({});
  const [termsCheckbox, setTermsCheckbox] = React.useState(true);
  const [showLogin, setShowLogin] = React.useState('show active');
  const [showRegister, setShowRegister] = React.useState('');
  const [registerName, setRegisterName] = React.useState('');
  const [registerEmail, setRegisterEmail] = React.useState('');
  const [loginEmail, setLoginEmail] = React.useState('');
  const [loginPassword, setLoginPassword] = React.useState('');
  const [registerPassword, setRegisterPassword] = React.useState('');
  const [registerRepeatPassword, setRegisterRepeatPassword] = React.useState('');
  const [loading, setLoading] = useState(false);


  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  const tabChangeLogin = () => {
    setShowLogin('show active');
    setShowRegister('');
  }

  const tabChangeRegister = () => {
    setShowLogin('');
    setShowRegister('show active');
  }

  const handleRegisterNameChange = (event) => {
    let value = event.target.value;
    setRegisterName(value);
  }

  const handleLoginEmailChange = (event) => {
    let value = event.target.value;
    setLoginEmail(value);
  }

  const handleLoginPasswordChange = (event) => {
    let value = event.target.value;
    setLoginPassword(value);
  }

  const handleRegisterEmailChange = (event) => {
    let value = event.target.value;
    setRegisterEmail(value);
  }

  const handleRegisterPasswordChange = (event) => {
    let value = event.target.value;
    setRegisterPassword(value);
  }

  const handleRegisterRepeatPasswordChange = (event) => {
    let value = event.target.value;
    setRegisterRepeatPassword(value);
  }

  const handleRegister = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    console.log('save registerRepeatPassword', registerName, registerEmail, registerPassword, registerRepeatPassword);

    if (loading) {
      return;
    }

    if (!termsCheckbox) {
      toast.warning('Please accept terms to register', toastOptions);
      return;
    } else if (!registerName) {
      toast.warning('Please enter your name', toastOptions);
      return;
    } else if (!registerEmail) {
      toast.warning('Please enter a valid email', toastOptions);
      return;
    } else if (!registerPassword) {
      toast.warning('Please enter the password', toastOptions);
      return;
    } else if (!registerRepeatPassword) {
      toast.warning('Please re-enter the password', toastOptions);
      return;
    } else if (registerPassword !== registerRepeatPassword) {
      toast.warning('Passwords do not match', toastOptions);
      return;
    }

    if (registerEmail && !emailRegex.test(registerEmail)) {
      toast.warning('Please enter a valid email', toastOptions);
      return;
    }

    const payload = {
      username: registerName.trim(),
      email: registerEmail.trim(),
      password: registerPassword.trim(),
      phoneNumber: '',
      address: '',
      pointsAvailable: '0',
      expiredOn: '---',
      plan: 'No Active Plan',
    }

    setLoading(true); 
    dispatch(resetMyAccountReducerFunc());
    let response = await handleAPIData('/api/register', payload);
    if (response.status === 'success' && response.data && response.data.email) {
      toast.success('You are registered successfully', toastOptions);
      setUserDetails({
        username: response.data.username,
        email: response.data.email
      });
      onCloseModal();
      setMyAccountSection(false);
      dispatch(changeInputFunc({ keyName: 'displayName', value: response.data.username }));
      dispatch(changeInputFunc({ keyName: 'displayEmail', value: response.data.email }));
      dispatch(changeInputFunc({ keyName: 'displayPhone', value: response.data.phoneNumber }));
      dispatch(changeInputFunc({ keyName: 'displayAddress', value: response.data.address }));
      dispatch(changeInputFunc({ keyName: 'pointsAvailable', value: response.data.pointsAvailable }));
      dispatch(changeInputFunc({ keyName: 'expiredOn', value: response.data.expiredOn }));
      dispatch(changeInputFunc({ keyName: 'plan', value: response.data.plan }));
      console.log('response', response.data);
    } else if (response.status === 'success' && response.data && response.data.isAlreadyRegistered) {
      toast.error(response.data.message, toastOptions);
    } else {
      toast.error('Something went wrong. Please try again.', toastOptions);
    }
    setLoading(false);
  };

  const handleMyAccountClick = () => {
    console.log('userDetails', userDetails);
    if (userDetails.email) {
      onCloseModal();
      setMyAccountSection(true);
    } else {
      setMyAccountSection(false);
      onOpenModal();
    }
  }

  const handleHomeClick = () => {
    setMyAccountSection(false);
    onCloseModal();
  }

  const handleLogOut = () => {
    setMyAccountSection(false);
    setUserDetails({});    
    dispatch(resetMyAccountReducerFunc());
    // console.log('$$$$$$$$$', store.getState());
  }

  const handleLogin = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (loading) {
      return;
    }
    if (!loginEmail) {
      toast.warning('Please enter registered email', toastOptions);
      return;
    } else if (!loginPassword) {
      toast.warning('Please enter the password', toastOptions);
      return;
    }

    if (loginEmail && !emailRegex.test(loginEmail)) {
      toast.warning('Please enter a valid email', toastOptions);
      return;
    }

    const payload = {
      email: loginEmail.trim(),
      password: loginPassword.trim()
    }

    setLoading(true); 
    dispatch(resetMyAccountReducerFunc());
    let response = await handleAPIData('/api/login', payload);
    console.log('sdfsdfsafdsfdsfdsfffd', response)
    if (response.status === 'success' && response.data && response.data.email) {
      toast.success(response.data.message, toastOptions);
      setUserDetails({
        username: response.data.username,
        email: response.data.email
      });
      onCloseModal();
      setMyAccountSection(false);
      dispatch(changeInputFunc({ keyName: 'displayName', value: response.data.username }));
      dispatch(changeInputFunc({ keyName: 'displayEmail', value: response.data.email }));
      dispatch(changeInputFunc({ keyName: 'displayPhone', value: response.data.phoneNumber }));
      dispatch(changeInputFunc({ keyName: 'displayAddress', value: response.data.address }));
      dispatch(changeInputFunc({ keyName: 'pointsAvailable', value: response.data.pointsAvailable }));
      dispatch(changeInputFunc({ keyName: 'expiredOn', value: response.data.expiredOn }));
      dispatch(changeInputFunc({ keyName: 'plan', value: response.data.plan }));
      console.log('response', response.data);
    } else if (response.status === 'success' && response.data && response.data.invalidPassword) {
      toast.error(response.data.message, toastOptions);
    } else if (response.status === 'success' && response.data && response.data.invalidUser) {
      toast.error(response.data.message, toastOptions);
    } else {
      toast.error('Something went wrong. Please try again.', toastOptions);
    }
    setLoading(false);
  };

  const callBackForLogOut = () => {
    alert('You have successfully logged out');
    handleLogOut();
  }

  
  return (
    <>
      <div>
        <nav className="navbar navbar-expand-lg bg-body-tertiary">
          <div className="container-fluid">
            <a className="navbar-brand" onClick={handleHomeClick}>Shivom Algo</a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
                <li className="nav-item text-center">
                  <span className="nav-menu-icon img-flight"></span> <br />
                  <a className="nav-link" aria-current="page">Algo</a>
                </li>
                <li className="nav-item text-center">
                  <span className="nav-menu-icon img-invest"></span> <br />
                  <a className="nav-link" aria-current="page">Pricing</a>
                </li>
                <li className="nav-item text-center">
                  <span className="nav-menu-icon img-holidays"></span> <br />
                  <a className="nav-link" aria-current="page">Terms</a>
                </li>
                {/* <li className="nav-item text-center">
                  <span className="nav-menu-icon img-cabs"></span> <br />
                  <a className="nav-link" aria-current="page">Disclaimer</a>
                </li>
                <li className="nav-item text-center">
                  <span className="nav-menu-icon img-invest"></span> <br />
                  <a className="nav-link" aria-current="page">Invest</a>
                </li> */}
              </ul>
              <ul className="navbar-nav mb-2 mb-lg-0 d-flex nav-secondary">
                <li className="nav-item" onClick={handleMyAccountClick}>
                  <a className="nav-link">My Account</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link">Support</a>
                </li>
                {
                  userDetails && userDetails.email && Object.keys(userDetails).length > 0 &&
                  <li className="nav-item" onClick={handleLogOut} >
                    <a className="nav-link">Log Out</a>
                  </li>
                }

              </ul>
            </div>
          </div>
        </nav>
        {
          !myAccountSection &&
          <section>
            {/* <div className="container-xxl py-5 section-block-hero">
              <div className="row align-items-center">
                <div className="col-lg-6 col-md-12 order-md-2 order-lg-1">
                  <div className="booking-form">
                    <div className="hero-form-title">Book for Hajj and Umrah</div>
                    <div className="row">
                      <div className="col">
                        <select className="form-select form-select-lg mb-3" aria-label="Large select example">
                          <option selected>Travelling from</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </select>
                      </div>
                      <div className="col">
                        <select className="form-select form-select-lg mb-3" aria-label="Large select example">
                          <option selected>Where to</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </select>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <div className="input-group mb-3">
                          <input type="text" className="form-control form-addon" placeholder="Travelling dates" aria-label="Travelling dates" aria-describedby="basic-addon2" />
                          <span className="input-group-text" id="basic-addon2"><i className="bi bi-calendar-event"></i></span>
                        </div>
                      </div>
                      <div className="col">
                        <select className="form-select form-select-lg mb-3" aria-label="Large select example">
                          <option selected>How many people</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </select>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <button type="button" className="btn btn-primary float-end">Search holidays</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 order-md-1 order-lg-2">
                  <h1 className="mb-4">Embark on a Sacred Journey with Us</h1>
                  <p className="hero-text mb-5">Your Trusted Companion for a Hassle-Free Hajj and Umrah Experience</p>
                </div>
              </div>
            </div> */}
            <div className="container-xxl py-5 section-block">
              <div className="row">
                <div className="col-auto me-auto">
                  <h2>This is Home Page. What Shivom Algo do will come</h2>
                </div>
                {/* <div className="col-auto">
                  <a>See all <i className="bi bi-arrow-right"></i></a>
                </div> */}
              </div>
              {/* <div className="row">
                <div className="col">
                  <div className="owl-carousel">
                    <a className="trending-destination">
                      <img src={"./assets/images/destination_images/img-1.png"} alt="" />
                      <h3 className="mt-3">Mecca</h3>
                      <p>20+ tours</p>
                    </a>
                    <a className="trending-destination">
                      <img src="./assets/images/destination_images/img-2.png" alt="" />
                      <h3 className="mt-3">Mecca</h3>
                      <p>20+ tours</p>
                    </a>
                    <a className="trending-destination">
                      <img src="./assets/images/destination_images/img-3.png" alt="" />
                      <h3 className="mt-3">Mecca</h3>
                      <p>20+ tours</p>
                    </a>
                    <a className="trending-destination">
                      <img src="./assets/images/destination_images/img-4.png" alt="" />
                      <h3 className="mt-3">Mecca</h3>
                      <p>20+ tours</p>
                    </a>
                    <a className="trending-destination">
                      <img src="./assets/images/destination_images/img-5.png" alt="" />
                      <h3 className="mt-3">Mecca</h3>
                      <p>20+ tours</p>
                    </a>
                    <a className="trending-destination">
                      <img src="./assets/images/destination_images/img-6.png" alt="" />
                      <h3 className="mt-3">Mecca</h3>
                      <p>20+ tours</p>
                    </a>
                    <a className="trending-destination">
                      <img src="./assets/images/destination_images/img-3.png" alt="" />
                      <h3 className="mt-3">Mecca</h3>
                      <p>20+ tours</p>
                    </a>
                  </div>
                </div>
              </div> */}
            </div>
            {/* <div className="container-xxl py-5 section-block">
              <div className="row">
                <div className="col">
                  <div className="colour-title">Find more places around you</div>
                  <h2>Discover muslim-friendly places around you</h2>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <a>
                    <img src="./assets/images/discover_images/img-1.png" className="img-style" alt="" />
                    <h3 className="mt-3">Find restaurants near you</h3>
                    <p>Discover a variety of delicious and authentic dining options in the holy city</p>
                  </a>
                </div>
                <div className="col">
                  <a>
                    <img src="./assets/images/discover_images/img-2.png" className="img-style" alt="" />
                    <h3 className="mt-3">Find restaurants near you</h3>
                    <p>Discover a variety of delicious and authentic dining options in the holy city</p>
                  </a>
                </div>
                <div className="col">
                  <a>
                    <img src="./assets/images/discover_images/img-3.png" className="img-style" alt="" />
                    <h3 className="mt-3">Find restaurants near you</h3>
                    <p>Discover a variety of delicious and authentic dining options in the holy city</p>
                  </a>
                </div>
              </div>
            </div> */}
            {/* <div className="container-xxl py-5 section-block">
              <div className="row">
                <div className="col-auto me-auto">
                  <div className="colour-title">Book online</div>
                  <h2>Halal friendly holidays, activities, and hotels</h2>
                </div>
                <div className="col-auto">
                  <a>See all <i className="bi bi-arrow-right"></i></a>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <a>
                    <img src="./assets/images/book_online/img-1.png" className="img-style" alt="" />
                    <div className="rating"><span>9/10</span>Exceptional (23 reviews)</div>
                    <h3>Novotel Thakher</h3>
                    <p>Makkah</p>
                    <h3 className="price">$39.00</h3>
                    <p>per night <br />
                      $78.00 total <br />
                      inclusive taxes and fees</p>
                  </a>
                </div>
                <div className="col">
                  <a>
                    <img src="./assets/images/book_online/img-2.png" className="img-style" alt="" />
                    <div className="rating"><span>9/10</span>Exceptional (23 reviews)</div>
                    <h3>Novotel Thakher</h3>
                    <p>Makkah</p>
                    <h3 className="price">$39.00</h3>
                    <p>per night <br />
                      $78.00 total <br />
                      inclusive taxes and fees</p>
                  </a>
                </div>
                <div className="col">
                  <a>
                    <img src="./assets/images/book_online/img-3.png" className="img-style" alt="" />
                    <div className="rating"><span>9/10</span>Exceptional (23 reviews)</div>
                    <h3>Novotel Thakher</h3>
                    <p>Makkah</p>
                    <h3 className="price">$39.00</h3>
                    <p>per night <br />
                      $78.00 total <br />
                      inclusive taxes and fees</p>
                  </a>
                </div>
                <div className="col">
                  <a>
                    <img src="./assets/images/book_online/img-4.png" className="img-style" alt="" />
                    <div className="rating"><span>9/10</span>Exceptional (23 reviews)</div>
                    <h3>Novotel Thakher</h3>
                    <p>Makkah</p>
                    <h3 className="mt-4">$39.00</h3>
                    <p>per night <br />
                      $78.00 total <br />
                      inclusive taxes and fees</p>
                  </a>
                </div>
              </div>
            </div>
            <div className="container-xxl py-5 section-block">
              <div className="row">
                <div className="col-auto me-auto">
                  <h2>Innovation zone</h2>
                </div>
                <div className="col-auto">
                  <a>See all <i className="bi bi-arrow-right"></i></a>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="owl-carousel">
                    <img src="./assets/images/innovation_zone/img-1.png" alt="" />
                    <img src="./assets/images/innovation_zone/img-2.png" alt="" />
                    <img src="./assets/images/innovation_zone/img-3.png" alt="" />
                    <img src="./assets/images/innovation_zone/img-4.png" alt="" />
                    <img src="./assets/images/innovation_zone/img-5.png" alt="" />
                    <img src="./assets/images/innovation_zone/img-2.png" alt="" />
                    <img src="./assets/images/innovation_zone/img-3.png" alt="" />
                    <img src="./assets/images/innovation_zone/img-4.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="container-xxl py-5 section-block">
              <div className="row">
                <div className="col">
                  <h2 className="app-section-title">Make your Umrah unforgettable: Enhance your spiritual experience with Nusuk app</h2>
                  <p className="app-text">Manage your reservations, stay informed and navigate effortlessly - all in one app</p>
                  <p className="get-app-text">Get app</p>
                  <span className="app-icons">
                    <a><img src="./assets/images/app-store.svg" alt="" /></a>
                    <a><img src="./assets/images/play-store.svg" alt="" /></a>
                  </span>
                </div>
                <div className="col">
                  <div className="app-image">
                    <img src="./assets/images/app-image.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="section-bg">
              <div className="container-xxl py-5 section-block">
                <div className="row">
                  <div className="col">
                    <h2>Customise your own Hajji & Umrah packages</h2>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-3 col-md-4 mb-3">
                    <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Select departure date" />
                  </div>
                  <div className="col-lg-3 col-md-4 mb-3">
                    <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Enter required duration" />
                  </div>
                  <div className="col-lg-3 col-md-4 mb-3">
                    <select className="form-select form-select-lg mb-3" aria-label="Large select example">
                      <option selected>Choose Makkah hotel</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                  </div>
                  <div className="col-lg-3 col-md-4 mb-3">
                    <select className="form-select form-select-lg mb-3" aria-label="Large select example">
                      <option selected>Pick Makkah hotel</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                  </div>
                  <div className="col-lg-3 col-md-4 mb-3">
                    <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Your name" />
                  </div>
                  <div className="col-lg-3 col-md-4 mb-3">
                    <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="No. of passengers" />
                  </div>
                  <div className="col-lg-3 col-md-4 mb-3">
                    <input type="email" className="form-control" id="exampleFormControlInput1" placeholder="Email ID" />
                  </div>
                  <div className="col-lg-3 col-md-4 mb-3">
                    <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Contact number" />
                  </div>
                  <div className="col-lg-3 col-md-4 mb-3">
                    <p className="mb-1">Include Ziyarat</p>
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                      <label className="form-check-label mt-1" htmlFor="flexCheckDefault">
                        Yes
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <button type="button" className="btn btn-primary float-end">Get price</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-xxl py-5 section-block">
              <div className="row">
                <div className="col">
                  <ul className="nav nav-tabs justify-content-center" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button className="nav-link active" id="tab-1" data-bs-toggle="tab" data-bs-target="#tab-1-pane" type="button" role="tab" aria-controls="tab-1-pane" aria-selected="true"><i className="bi bi-info-circle"></i> <br /> Entrance to Umrah</button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button className="nav-link" id="tab-2" data-bs-toggle="tab" data-bs-target="#tab-2-pane" type="button" role="tab" aria-controls="tab-2-pane" aria-selected="false"><i className="bi bi-info-circle"></i> <br /> Travel and Access</button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button className="nav-link" id="tab-3" data-bs-toggle="tab" data-bs-target="#tab-3-pane" type="button" role="tab" aria-controls="tab-3-pane" aria-selected="false"><i className="bi bi-info-circle"></i> <br /> Miqat</button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button className="nav-link" id="tab-4" data-bs-toggle="tab" data-bs-target="#tab-4-pane" type="button" role="tab" aria-controls="tab-4-pane" aria-selected="false"><i className="bi bi-info-circle"></i> <br /> Ihram</button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button className="nav-link" id="tab-5" data-bs-toggle="tab" data-bs-target="#tab-5-pane" type="button" role="tab" aria-controls="tab-5-pane" aria-selected="false"><i className="bi bi-info-circle"></i> <br /> Access to santuary</button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button className="nav-link" id="tab-6" data-bs-toggle="tab" data-bs-target="#tab-6-pane" type="button" role="tab" aria-controls="tab-6-pane" aria-selected="false"><i className="bi bi-info-circle"></i> <br /> Sa’i</button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button className="nav-link" id="tab-7" data-bs-toggle="tab" data-bs-target="#tab-7-pane" type="button" role="tab" aria-controls="tab-7-pane" aria-selected="false"><i className="bi bi-info-circle"></i> <br /> Entrance to Ziyarah</button>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="row tab-margin">
                <div className="col">
                  <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active" id="tab-1-pane" role="tabpanel" aria-labelledby="tab-1" tabIndex="0">
                      <div className="row">
                        <div className="col">
                          <div className="tab-title">Umrah & Ziyarah</div>
                          <p className="text-dark">Learn the essential rituals and steps to fulfill your Umrah pilgrimage, ensuring a spiritually fulfilling journey. Understand the significance of visiting Al Masjid An Nabawi, the Prophet's Mosque, and the etiquettes to observe while there.
                            Gain insights into the historical and spiritual importance of these acts of worship.</p>
                        </div>
                        <div className="col">
                          <img src="./assets/images/tab-img.png" className="img-fluid" alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane fade" id="tab-2-pane" role="tabpanel" aria-labelledby="tab-2" tabIndex="0">
                      <div className="row">
                        <div className="col">
                          <div className="tab-title">Umrah & Ziyarah</div>
                          <p className="text-dark">Learn the essential rituals and steps to fulfill your Umrah pilgrimage, ensuring a spiritually fulfilling journey. Understand the significance of visiting Al Masjid An Nabawi, the Prophet's Mosque, and the etiquettes to observe while there.
                            Gain insights into the historical and spiritual importance of these acts of worship.</p>
                        </div>
                        <div className="col">
                          <img src="./assets/images/tab-img.png" className="img-fluid" alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane fade" id="tab-3-pane" role="tabpanel" aria-labelledby="tab-3" tabIndex="0">
                      <div className="row">
                        <div className="col">
                          <div className="tab-title">Umrah & Ziyarah</div>
                          <p className="text-dark">Learn the essential rituals and steps to fulfill your Umrah pilgrimage, ensuring a spiritually fulfilling journey. Understand the significance of visiting Al Masjid An Nabawi, the Prophet's Mosque, and the etiquettes to observe while there.
                            Gain insights into the historical and spiritual importance of these acts of worship.</p>
                        </div>
                        <div className="col">
                          <img src="./assets/images/tab-img.png" className="img-fluid" alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane fade" id="tab-4-pane" role="tabpanel" aria-labelledby="tab-4" tabIndex="0">
                      <div className="row">
                        <div className="col">
                          <div className="tab-title">Umrah & Ziyarah</div>
                          <p className="text-dark">Learn the essential rituals and steps to fulfill your Umrah pilgrimage, ensuring a spiritually fulfilling journey. Understand the significance of visiting Al Masjid An Nabawi, the Prophet's Mosque, and the etiquettes to observe while there.
                            Gain insights into the historical and spiritual importance of these acts of worship.</p>
                        </div>
                        <div className="col">
                          <img src="./assets/images/tab-img.png" className="img-fluid" alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane fade" id="tab-5-pane" role="tabpanel" aria-labelledby="tab-5" tabIndex="0">
                      <div className="row">
                        <div className="col">
                          <div className="tab-title">Umrah & Ziyarah</div>
                          <p className="text-dark">Learn the essential rituals and steps to fulfill your Umrah pilgrimage, ensuring a spiritually fulfilling journey. Understand the significance of visiting Al Masjid An Nabawi, the Prophet's Mosque, and the etiquettes to observe while there.
                            Gain insights into the historical and spiritual importance of these acts of worship.</p>
                        </div>
                        <div className="col">
                          <img src="./assets/images/tab-img.png" className="img-fluid" alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane fade" id="tab-6-pane" role="tabpanel" aria-labelledby="tab-6" tabIndex="0">
                      <div className="row">
                        <div className="col">
                          <div className="tab-title">Umrah & Ziyarah</div>
                          <p className="text-dark">Learn the essential rituals and steps to fulfill your Umrah pilgrimage, ensuring a spiritually fulfilling journey. Understand the significance of visiting Al Masjid An Nabawi, the Prophet's Mosque, and the etiquettes to observe while there.
                            Gain insights into the historical and spiritual importance of these acts of worship.</p>
                        </div>
                        <div className="col">
                          <img src="./assets/images/tab-img.png" className="img-fluid" alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane fade" id="tab-7-pane" role="tabpanel" aria-labelledby="tab-7" tabIndex="0">
                      <div className="row">
                        <div className="col">
                          <div className="tab-title">Umrah & Ziyarah</div>
                          <p className="text-dark">Learn the essential rituals and steps to fulfill your Umrah pilgrimage, ensuring a spiritually fulfilling journey. Understand the significance of visiting Al Masjid An Nabawi, the Prophet's Mosque, and the etiquettes to observe while there.
                            Gain insights into the historical and spiritual importance of these acts of worship.</p>
                        </div>
                        <div className="col">
                          <img src="./assets/images/tab-img.png" className="img-fluid" alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </section>
        }
        {
          myAccountSection && userDetails.email &&
          <section>
            <div className="container-xxl section-block-inner">
              <div className="row align-items-center">
                <div className="col-lg-12 col-md-12 text-center">
                  <h1 className="mb-2">My account</h1>
                  <p className="hero-text">View/manage your account details</p>
                </div>
              </div>
            </div>
            <div className="container-xxl py-5 section-block">
              <div className="row">
                <div className="col-3">
                  <div className="row">
                    <DisplayProfile />
                  </div>
                </div>
                <div className="col-9">
                  <ul className="nav nav-tabs justify-content-center" id="my-account-details" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button className="nav-link active" id="edit-profile" data-bs-toggle="tab" data-bs-target="#my-account-edit-profile" type="button" role="tab" aria-selected="true">Edit profile</button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button className="nav-link" id="change-password" data-bs-toggle="tab" data-bs-target="#my-account-change-password" type="button" role="tab" aria-selected="false">Change password</button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button className="nav-link" id="subscription" data-bs-toggle="tab" data-bs-target="#my-account-subscription" type="button" role="tab" aria-selected="false">Subscription</button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button className="nav-link" id="payment-methods" data-bs-toggle="tab" data-bs-target="#my-account-payment-method" type="button" role="tab" aria-selected="false">Payment method</button>
                    </li>
                  </ul>
                  <div className="row tab-margin">
                    <div className="col">
                      <div className="tab-content" id="my-account-content">
                        <EditProfile id={"my-account-edit-profile"} />
                        <ChangePassword id={"my-account-change-password"} callBackForLogOut={callBackForLogOut} />
                        <Subscription id={"my-account-subscription"} />
                        <PaymentMethod id={"my-account-payment-method"} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        }
        <div className="section-bg">
          <div className="container-xxl py-5 section-block">
            <div className="row align-items-center">
              <div className="col">
                <div className="logo mb-2">Shivom Algo</div>
                <p className="text-dark">© 2024 Shivom Algo. All rights reserved.</p>
              </div>
              <div className="col">
                <ul className="list-inline float-end">
                  <li className="list-inline-item"><a>Terms of Use</a></li>
                  <li className="list-inline-item">|</li>
                  <li className="list-inline-item"><a>Privacy and Cookies</a></li>
                  <li className="list-inline-item">|</li>
                  <li className="list-inline-item"><a>Cookie consent</a></li>
                  <li className="list-inline-item">|</li>
                  <li className="list-inline-item"><a>Contact us</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal open={open} onClose={onCloseModal} center>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="my-account-modal-label">{showLogin ? 'Login' : 'Register'}</h1>
            </div>
            <div className="modal-body">
              <ul className="nav nav-pills nav-justified mb-3" id="ex1" role="tablist">
                <li className="nav-item" role="presentation">
                  <a className={`nav-link ${showLogin}`} id="tab-login" data-mdb-pill-init role="tab"
                    onClick={tabChangeLogin}>Login</a>
                </li>
                <li className="nav-item" role="presentation">
                  <a className={`nav-link ${showRegister}`} id="tab-register" data-mdb-pill-init role="tab"
                    onClick={tabChangeRegister}>Register</a>
                </li>
              </ul>
              <div className="tab-content">
                <div className={`tab-pane fade login-wrapper ${showLogin}`} id="tab-login" role="tabpanel">

                  <div data-mdb-input-init className="form-outline mb-4">
                    <input type="email" id="loginName" className="form-control" onChange={handleLoginEmailChange} placeholder='Email'/>
                    {/* <label className="form-label" htmlFor="loginName">Email</label> */}
                  </div>


                  <div data-mdb-input-init className="form-outline mb-4">
                    <input type="password" id="loginPassword" className="form-control" onChange={handleLoginPasswordChange} placeholder='Password' />
                    {/* <label className="form-label" htmlFor="loginPassword">Password</label> */}
                  </div>


                  {/* <div className="row mb-4">
                    <div className="col-md-6 d-flex justify-content-center">
                      <a href="#!">Forgot password?</a>
                    </div>
                  </div> */}


                  <button className="btn btn-primary btn-block mb-4" onClick={handleLogin}>Sign in</button>
                  <button className="btn btn-secondary btn-block mb-3 mx-4" onClick={onCloseModal}>Cancel</button>


                  <div className="text-center">
                    <p>Not a member? <a href="#!" onClick={tabChangeRegister}>Register</a></p>
                  </div>
                </div>
                <div className={`tab-pane fade register-wrapper ${showRegister}`} id="tab-register" role="tabpanel">
                  <div data-mdb-input-init className="form-outline mb-4">
                    <input type="text" id="register-name" className="form-control" onChange={handleRegisterNameChange} value={registerName} placeholder='Name' />
                    {/* <label className="form-label" htmlFor="register-name">Name</label> */}
                  </div>


                  <div data-mdb-input-init className="form-outline mb-4">
                    <input type="text" id="register-email" className="form-control" onChange={handleRegisterEmailChange} value={registerEmail} placeholder='Email' />
                    {/* <label className="form-label" htmlFor="register-email">Email</label> */}
                  </div>


                  <div data-mdb-input-init className="form-outline mb-4">
                    <input type="password" id="register-password" className="form-control" onChange={handleRegisterPasswordChange} value={registerPassword} placeholder='Password' />
                    {/* <label className="form-label" htmlFor="register-password">Password</label> */}
                  </div>


                  <div data-mdb-input-init className="form-outline mb-4">
                    <input type="text" id="register-repeart-password" className="form-control" onChange={handleRegisterRepeatPasswordChange} value={registerRepeatPassword} placeholder='Repeat Password' />
                    {/* <label className="form-label" htmlFor="register-repeart-password">Repeat password</label> */}
                  </div>

                  <div className="form-check d-flex justify-content-center mb-4">
                    <input className="form-check-input me-2" type="checkbox" value="termsChecbox" id="register-check" defaultChecked={termsCheckbox} onChange={() => setTermsCheckbox(!termsCheckbox)} />
                    <label className="form-check-label" htmlFor="register-check">
                      I have read and agree to the terms
                    </label>
                  </div>

                  <button className="btn btn-primary btn-block mb-3" onClick={handleRegister}>Sign in</button>
                  <button className="btn btn-secondary btn-block mb-3 mx-4" onClick={onCloseModal}>Cancel</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <ToastContainer />
    </>
  )
};

export default App;
