import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  default: ''
};

export const myAccountSlice = createSlice({
  name: 'myAccount',
  initialState,
  reducers: {
    changeInputFunc: (state, action) => {
      // console.log(action.payload)
      state[action.payload.keyName] = action.payload.value
    },
    resetInputFunc: (state, action) => {
      state[action.payload] = ''
    },
    resetMyAccountReducerFunc: (state, action) => {
      console.log('initialState', initialState)
      return initialState;
    }
  }
})

// Action creators are generated for each case reducer function
export const { 
  changeInputFunc,
  resetInputFunc,
  resetMyAccountReducerFunc
 } = myAccountSlice.actions

export default myAccountSlice.reducer    
