import React from 'react';
import { useSelector } from 'react-redux';

const Subscription = ({ id }) => {

  const { pointsAvailable, expiredOn, plan } = useSelector(state => state.myAccount );
  console.log('pointsAvailable, expiredOn, plan', pointsAvailable, expiredOn, plan);
  const displayArray = [
    { label: 'Points Available', value: pointsAvailable },    
    { label: 'Expired On', value: expiredOn },    
    { label: 'Plan', value: plan }
  ]
  return (
    <>
      <div className="tab-pane fade" id={id} role="tabpanel" tabIndex="0">
        {
          displayArray && displayArray.length > 0 && displayArray.map((obj) => {
            return (
              <div className="col-12 mb-4">
                <h6>{obj.label}</h6>
                <p>{obj.value}</p>
              </div>
            )
          })
        }
      </div>
    </>
  )
};

export default Subscription;
